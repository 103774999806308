/**
 * Grid Variables - CH
 */
/**
 * Custom SASS Mixins - CH
 */
/**
 * Grid Variables - CH
 */
.logout {
  /**
 * Grid Variables - CH
 */
  /**
 * Custom SASS Mixins - CH
 */
  /**
 * Grid Variables - CH
 */
  /* Custom Buttons - CH */
}

.logout .btn {
  display: inline-block;
  position: relative;
  padding: 1em;
  border-radius: 0;
  font-family: 'Oswald', sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.875em;
  text-decoration: none;
  text-transform: uppercase;
}

.logout .btn:hover {
  text-decoration: none;
}

.logout .btn.btn-primary:after, .logout .component.load-more .component-content input[type='button']:after, .logout .btn.btn-link:after {
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 0;
  width: 1.5em;
  height: 2px;
  transform: translateY(-50%);
  transition: transform 0.2s ease;
  background-color: #707070;
  content: '';
  will-change: transform;
}

.logout .btn.btn-primary:hover:after, .logout .component.load-more .component-content input[type='button']:hover:after, .logout .btn.btn-link:hover:after {
  transform: translateX(-6px) translateY(-50%);
}

.logout .btn.btn-primary, .logout .component.load-more .component-content input[type='button'] {
  padding: 1.375em 5em 1.25em 1.125em;
  border-color: #000000;
  background-color: #000000;
  color: #fff;
  text-decoration: none;
}

.logout .btn.btn-primary:after, .logout .component.load-more .component-content input[type='button']:after {
  right: 1.6875em;
  background-color: #FFFFFF;
}

.logout .btn.btn-primary:hover, .logout .component.load-more .component-content input:hover[type='button'], .logout .btn.btn-primary:focus, .logout .component.load-more .component-content input:focus[type='button'] {
  border-color: #1a1a1a;
  background-color: #1a1a1a;
  color: #fff;
}

.logout .btn.btn-link {
  padding-right: 2.375em;
  padding-left: 0;
  text-decoration: none;
  color: #ee2737;
}

.logout .btn.btn-link:after {
  background-color: #ee2737;
}

.logout .btn.btn-link:hover {
  color: #ee2737;
  text-decoration: none;
}

.logout .btn.btn-link.text-white,
.text-white .logout .btn.btn-link {
  color: #fff;
}

.logout .btn.btn-link.text-white:after,
.text-white .logout .btn.btn-link:after {
  background-color: #FFFFFF;
}

.logout .btn.btn-link.text-white:hover,
.text-white .logout .btn.btn-link:hover {
  color: #fff;
}

.logout p .btn-link {
  margin-top: 1em;
}

.logout a:focus {
  opacity: 0.5;
}
